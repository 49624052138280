<template>
  <div class="d-flex flex-column flex-root">
    <Toast />
    <Toast position="top-left" group="tl" />

    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <!--begin::Body-->
      <div
        class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"
      >
        <!--begin::Form-->
        <div class="d-flex flex-center flex-column flex-lg-row-fluid">
          <!--begin::Wrapper-->
          <div class="w-lg-500px p-10">
            <!--begin::Form-->
            <validation-observer ref="ValidationSignIn">
              <form @submit.prevent="ValidationSignIn">
                <!--begin::Heading-->
                <div class="text-center mb-11">
                  <!--begin::Title-->
                  <h1 class="text-dark fw-bolder mb-3">Sign In</h1>
                  <!--end::Title-->
                  <!--begin::Subtitle-->
                  <div class="text-gray-500 fw-semibold fs-6">
                    Masuk Sebagai Pengelola PasarSafe Platform
                  </div>
                  <!--end::Subtitle=-->
                </div>
                <!--begin::Heading-->

                <!--begin::Input group=-->
                <div class="fv-row mb-8">
                  <!--begin::Email-->
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Alamat Email"
                  >
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      autocomplete="off"
                      class="form-control bg-transparent"
                      v-model="form.email"
                    />
                    <div class="text-danger mt-2">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                  <!--end::Email-->
                </div>
                <!--end::Input group=-->
                <div class="fv-row mb-3">
                  <!--begin::Password-->
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Password"
                  >
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      autocomplete="off"
                      class="form-control bg-transparent"
                      v-model="form.password"
                    />
                    <div class="text-danger mt-2">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                  <!--end::Password-->
                </div>
                <!--end::Input group=-->

                <!--begin::Submit button-->
                <div class="d-grid mb-10">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    class="btn btn-primary"
                    :disabled="loading"
                  >
                    <!--begin::Indicator label-->
                    <span class="indicator-label">{{
                      loading == true ? "Mohon Tunggu" : "Sign In"
                    }}</span>
                    <!--end::Indicator label-->
                  </button>
                </div>
                <!--end::Submit button-->
              </form>
            </validation-observer>
            <!--end::Form-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Form-->
      </div>
      <!--end::Body-->
      <!--begin::Aside-->
      <div
        class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bg-image-auth"
      >
        <!--begin::Content-->
        <div
          class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"
        >
          <!--begin::Logo-->
          <a href="#" class="mb-0 mb-lg-12">
            <img
              alt="Logo"
              src="https://mdigitalhub.sirv.com/PasarSafe/pasar_safe_white.png"
              class="h-60px h-lg-75px"
            />
            <h2 class="text-white">PasarSafe</h2>
          </a>
          <!--end::Logo-->
          <!--begin::Image-->
          <img
            class="d-none d-lg-block mx-auto w-175px w-md-50 w-xl-400px mb-10 mb-lg-20"
            src="@/assets/media/misc/auth-screens.png"
            alt=""
          />
          <!--end::Image-->
          <!--begin::Title-->
          <h1
            class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7"
          >
            Investing in Yourself and Improving Your Self
          </h1>
          <!--end::Title-->
          <!--begin::Text-->
          <div class="d-none d-lg-block text-white fs-base text-center">
            "Investing in Yourself and Improving Your Self" menginvestasikan
            diri Anda dan meningkatkan diri Anda lebih baik dari apa pun! karena
            hanya ibumu yang bisa menerima apa adanya, tidak dengan orang lain,
            <b><i>,- Dede Hidayatullah</i></b>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Aside-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
</template>

<script>
import { TokenService, AuthenticationError } from "@/services";
import { ApiData } from "@/api/server";
import NProgress from "nprogress";
export default {
  name: "Signin",
  data() {
    return {
      loading: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    document.body.classList.add("app-blank");
  },
  methods: {
    ValidationSignIn() {
      this.$refs.ValidationSignIn.validate().then((success) => {
        if (!success) {
          this.$toast.add({
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Silahkan Check kembali form inputan anda",
            life: 3000,
          });
        } else {
          this.LoginAccount();
        }
      });
    },

    LoginAccount() {
      this.loading = true;
      NProgress.start();
      NProgress.set(0.1);
      ApiData.post("login", this.form)
        .then((response) => {
          NProgress.done();
          this.loading = false;
          var detail = response.data;

          TokenService.saveToken(detail.token);
          TokenService.saveProfile(detail.data);
          ApiData.setHeaders(`Bearer ${detail.token}`);

          setTimeout(() => {
            this.$router.push({ name: "dashboard" });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          NProgress.done();
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: error.response.data.message,
            life: 3000,
          }); 
        });
    },
  },
  beforeDestroy() {
    document.body.classList.remove("app-blank");
  },
};
</script>

<style>
.bg-image-auth {
  background-image: url(../assets/media/misc/auth-bg.png);
}
</style>
