import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import NProgress from 'nprogress'
import { TokenService } from '@/services/modules/service.storage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      page_name: 'Login Account',
    },
  },

  {
    path: '/',
    component: () => import('../components'),
    // beforeEnter: authenticate,
    redirect: '/administrator/home',

    children: [
      /**
       * Dashboard Route
       */

      {
        name: 'dashboard',
        path: '/administrator/home',
        component: () => import('../views/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          page_name: 'Dashboard',
        },
      },

      /**
       * Profile Route
       */

      {
        name: 'profile',
        path: '/administrator/profile',
        component: () => import('../views/auth/profile.vue'),
        meta: {
          requiresAuth: true,
          page_name: 'Profil Saya',
        },
      },

      /**
       * Moduling
       */

      {
        path: '/administrator/module-feature',
        component: () => import('../views/module'),
        redirect: '/administrator/module-feature/module',
        children: [
          {
            name: 'module_list',
            path: 'module',
            meta: {
              requiresAuth: true,
              page_name: 'Module Fiture',
            },
            component: () => import('../views/module/list'),
          },
          {
            path: '/administrator/module-feature/detail',
            component: () => import('../views/module/detail'),
            children: [
              {
                name: 'module_detail_summery',
                path: 'summery/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Ringkasan Penggunaan Modul Fitur',
                },
                component: () => import('../views/module/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Package Pricing
       */
      {
        path: '/administrator/package-pricing',
        component: () => import('../views/package'),
        redirect: '/administrator/package-pricing/price',
        children: [
          {
            name: 'group_pricing',
            path: 'group',
            meta: {
              requiresAuth: true,
              page_name: 'Group Paket Harga',
            },
            component: () => import('../views/package/group'),
          },
          {
            name: 'detail_pricing',
            path: 'detail-pricing',
            meta: {
              requiresAuth: true,
              page_name: 'Detail Paket Modul',
            },
            component: () => import('../views/package/package_detail'),
          },
          {
            name: 'pricing_list',
            path: 'price',
            meta: {
              requiresAuth: true,
              page_name: 'Paket Harga Berlangganan',
            },
            component: () => import('../views/package/list'),
          },
          {
            path: '/administrator/package-pricing/detail',
            component: () => import('../views/package/detail'),
            children: [
              {
                name: 'package_detail_summery',
                path: 'summery/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Ringkasan Paket Harga',
                },
                component: () => import('../views/package/detail/summary.vue'),
              },
              {
                name: 'package_module_access',
                path: 'module-akses/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Akses Modul',
                },
                component: () =>
                  import('../views/package/detail/module_akses.vue'),
              },
              {
                name: 'package_setting',
                path: 'settings/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Pengaturan dan Edit Paket',
                },
                component: () => import('../views/package/detail/settings.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Transaction Package
       */

      {
        path: '/administrator/transactions',
        component: () => import('../views/transaction'),
        redirect: '/administrator/transactions/package',
        children: [
          {
            path: '/administrator/transactions/package',
            component: () => import('../views/transaction/package'),
            redirect: '/administrator/transactions/package/list',
            children: [
              {
                name: 'transaction_package_list',
                path: 'list',
                meta: {
                  requiresAuth: true,
                  page_name: 'Daftar Transaksi Penjualan Paket',
                },
                component: () =>
                  import('../views/transaction/package/list.vue'),
              },

              {
                name: 'transaction_package_detail',
                path: 'detail/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Transaksi Penjualan Paket',
                },
                component: () =>
                  import('../views/transaction/package/detail.vue'),
              },
              {
                name: 'package_transaction_new',
                path: 'create-new-transaction-package',
                meta: {
                  requiresAuth: true,
                  page_name: 'Buat Transaksi Paket Baru',
                },
                component: () =>
                  import('../views/transaction/package/create.vue'),
              },
              {
                name: 'package_transaction_migration',
                path: 'create-migration-transaction-package',
                meta: {
                  requiresAuth: true,
                  page_name: 'Migrasi Transaksi Paket',
                },
                component: () =>
                  import('../views/transaction/package/migration.vue'),
              },
              {
                name: 'package_transaction_by_business',
                path: 'create-by-business-transaction-package',
                meta: {
                  requiresAuth: true,
                  page_name: 'Buat Transaksi Per Bisnis',
                },
                component: () =>
                  import('../views/transaction/package/one_click.vue'),
              },
            ],
          },
          {
            path: '/administrator/transactions/affiliate',
            component: () => import('../views/transaction/affiliate'),
            redirect: '/administrator/transactions/affiliate/list',
            children: [
              {
                name: 'transaction_affiliate_list',
                path: 'list',
                meta: {
                  requiresAuth: true,
                  page_name: 'Daftar Transaksi Affiliator',
                },
                component: () =>
                  import('../views/transaction/affiliate/list.vue'),
              },

              {
                name: 'transaction_affiliate_wd',
                path: 'wd',
                meta: {
                  requiresAuth: true,
                  page_name: 'Daftar Permintaan Penarikan Saldo',
                },
                component: () =>
                  import('../views/transaction/affiliate/wd.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Business Owner
       */

      {
        path: '/administrator/master-data/business-owner',
        component: () => import('../views/masterdata/owners'),
        redirect: '/administrator/master-data/business-owner/list',
        children: [
          {
            name: 'business_owner_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Pemilik Bisnis',
            },
            component: () => import('../views/masterdata/owners/list.vue'),
          },

          {
            path: '/administrator/master-data/business-owner/detail',
            component: () => import('../views/masterdata/owners/detail'),
            redirect:
              '/administrator/master-data/business-owner/detail/overview',
            children: [
              {
                name: 'business_owner_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Business Owner',
                },
                component: () =>
                  import('../views/masterdata/owners/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Merchant / Business Registration
       */

      {
        path: '/administrator/master-data/merchant',
        component: () => import('../views/masterdata/merchants'),
        redirect: '/administrator/master-data/merchant/list',
        children: [
          {
            name: 'merchant_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Bisnis Merchant',
            },
            component: () => import('../views/masterdata/merchants/list.vue'),
          },

          {
            path: '/administrator/master-data/merchant/detail',
            component: () => import('../views/masterdata/merchants/detail'),
            redirect: '/administrator/master-data/merchant/detail/overview',
            children: [
              {
                name: 'merchant_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Business Merchant',
                },
                component: () =>
                  import('../views/masterdata/merchants/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Merchant Store
       */

      {
        path: '/administrator/master-data/stores',
        component: () => import('../views/masterdata/stores'),
        redirect: '/administrator/master-data/stores/list',
        children: [
          {
            name: 'stores_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Store Merchant',
            },
            component: () => import('../views/masterdata/stores/list.vue'),
          },

          {
            path: '/administrator/master-data/stores/detail',
            component: () => import('../views/masterdata/stores/detail'),
            redirect: '/administrator/master-data/stores/detail/overview',
            children: [
              {
                name: 'store_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Store Merchant',
                },
                component: () =>
                  import('../views/masterdata/stores/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Affiliator Route
       */

      {
        path: '/administrator/master-data/affiliator',
        component: () => import('../views/masterdata/affiliator'),
        redirect: '/administrator/master-data/affiliator/list',
        children: [
          {
            name: 'affiliator_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Affiliator',
            },
            component: () => import('../views/masterdata/affiliator/list.vue'),
          },

          {
            path: '/administrator/master-data/affiliator/detail',
            component: () => import('../views/masterdata/affiliator/detail'),
            redirect: '/administrator/master-data/affiliator/detail/overview',
            children: [
              {
                name: 'affiliator_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Affiliator',
                },
                component: () =>
                  import('../views/masterdata/affiliator/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Users Route
       */

      {
        path: '/administrator/master-data/users',
        component: () => import('../views/masterdata/users'),
        redirect: '/administrator/master-data/users/list',
        children: [
          {
            name: 'users_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Pengguna',
            },
            component: () => import('../views/masterdata/users/list.vue'),
          },

          {
            path: '/administrator/master-data/users/detail',
            component: () => import('../views/masterdata/users/detail'),
            redirect: '/administrator/master-data/affiliator/detail/overview',
            children: [
              {
                name: 'users_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Pengguna',
                },
                component: () =>
                  import('../views/masterdata/users/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Voucher Route
       */

      {
        path: '/administrator/master-data/voucher',
        component: () => import('../views/masterdata/voucher'),
        redirect: '/administrator/master-data/voucher/list',
        children: [
          {
            name: 'voucher_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Voucher Diskon Package',
            },
            component: () => import('../views/masterdata/voucher/list.vue'),
          },
          {
            name: 'voucher_create',
            path: 'create',
            meta: {
              requiresAuth: true,
              page_name: 'Buat Voucher Diskon Package',
            },
            component: () => import('../views/masterdata/voucher/create.vue'),
          },
          {
            name: 'voucher_update',
            path: 'update/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Voucher Diskon Package',
            },
            component: () => import('../views/masterdata/voucher/update.vue'),
          },
        ],
      },

      /**
       * Partnership Route
       */

      {
        path: '/administrator/master-data/partnership',
        component: () => import('../views/masterdata/partnership'),
        redirect: '/administrator/master-data/partnership/list',
        children: [
          {
            name: 'partnership_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Partnership',
            },
            component: () => import('../views/masterdata/partnership/list.vue'),
          },
          {
            name: 'partnership_create',
            path: 'create',
            meta: {
              requiresAuth: true,
              page_name: 'Tambah Partnership',
            },
            component: () => import('../views/masterdata/partnership/create.vue'),
          },
          {
            name: 'partnership_update',
            path: 'update/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Partnership',
            },
            component: () => import('../views/masterdata/partnership/update.vue'),
          },
        ],
      },

      /**
       * Business Type
       */

      {
        path: '/administrator/master-data/business-type',
        component: () => import('../views/masterdata/business_type'),
        redirect: '/administrator/master-data/business-type/list',
        children: [
          {
            name: 'business_type_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Tipe Bisnis',
            },
            component: () =>
              import('../views/masterdata/business_type/list.vue'),
          },

          {
            path: '/administrator/master-data/business-type/detail',
            component: () => import('../views/masterdata/business_type/detail'),
            redirect:
              '/administrator/master-data/business-type/detail/overview',
            children: [
              {
                name: 'business_type_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Business Tipe',
                },
                component: () =>
                  import(
                    '../views/masterdata/business_type/detail/summary.vue'
                  ),
              },
            ],
          },
        ],
      },

      /**
       * Registration Information
       */

      {
        path: '/administrator/master-data/regist-information',
        component: () => import('../views/masterdata/info_regis'),
        redirect: '/administrator/master-data/regist-information/list',
        children: [
          {
            name: 'info_regis_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Tipe Bisnis',
            },
            component: () => import('../views/masterdata/info_regis/list.vue'),
          },

          {
            path: '/administrator/master-data/regist-information/detail',
            component: () => import('../views/masterdata/info_regis/detail'),
            redirect:
              '/administrator/master-data/regist-information/detail/overview',
            children: [
              {
                name: 'info_regis_overview',
                path: 'overview/:id',
                meta: {
                  requiresAuth: true,
                  page_name: 'Detail Ringkasan Business Tipe',
                },
                component: () =>
                  import('../views/masterdata/info_regis/detail/summary.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Setting Internal
       */
      {
        path: '/administrator/pengaturan-internal',
        component: () => import('../views/package'),
        redirect: '/administrator/pengaturan-internal/general',
        children: [
          {
            name: 'general_setting',
            path: 'general',
            meta: {
              requiresAuth: true,
              page_name: 'Pengaturan General ',
            },
            component: () => import('../views/settings/general.vue'),
          },
        ],
      },

      /**
       * Media Berita
       */

      {
        path: '/administrator/media/news',
        component: () => import('../views/media/news'),
        redirect: '/administrator/media/news/list',
        children: [
          {
            name: 'news_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Artikel & Blog',
            },
            component: () => import('../views/media/news/list.vue'),
          },
          {
            name: 'news_create',
            path: 'create',
            meta: {
              requiresAuth: true,
              page_name: 'Publish Artikel & Blog',
            },
            component: () => import('../views/media/news/create.vue'),
          },
          {
            name: 'news_update',
            path: 'edit/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Artikel & Blog',
            },
            component: () => import('../views/media/news/update.vue'),
          },
          {
            name: 'news_category',
            path: 'category',
            meta: {
              requiresAuth: true,
              page_name: 'Kategori Berita & Blog',
            },
            component: () => import('../views/media/news/category.vue'),
          },
        ],
      },

      /**
       * Media Video Tutorial
       */

      {
        path: '/administrator/media/tutorial',
        component: () => import('../views/media/video'),
        redirect: '/administrator/media/tutorial/list',
        children: [
          {
            name: 'tutorial_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Tutorial',
            },
            component: () => import('../views/media/video/list.vue'),
          },
          {
            name: 'tutorial_create',
            path: 'create',
            meta: {
              requiresAuth: true,
              page_name: 'Publish Tutorial',
            },
            component: () => import('../views/media/video/create.vue'),
          },
          {
            name: 'tutorial_update',
            path: 'edit/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Tutorial',
            },
            component: () => import('../views/media/video/update.vue'),
          },
          {
            name: 'tutorial_category',
            path: 'category',
            meta: {
              requiresAuth: true,
              page_name: 'Kategori Tutorial Video',
            },
            component: () => import('../views/media/video/category.vue'),
          },
        ],
      },

      /**
       * Media Pengumuman
       */

      {
        path: '/administrator/media/pengumuman',
        component: () => import('../views/media/pengumuman'),
        redirect: '/administrator/media/pengumuman/list',
        children: [
          {
            name: 'pengumuman_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Pengumuman',
            },
            component: () => import('../views/media/pengumuman/list.vue'),
          },
          {
            name: 'pengumuman_create',
            path: 'create',
            meta: {
              requiresAuth: true,
              page_name: 'Publish Pengumuman',
            },
            component: () => import('../views/media/pengumuman/create.vue'),
          },
          {
            name: 'pengumuman_update',
            path: 'edit/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Pengumuman',
            },
            component: () => import('../views/media/pengumuman/update.vue'),
          },
          {
            name: 'pengumuman_category',
            path: 'category',
            meta: {
              requiresAuth: true,
              page_name: 'Kategori Pengumuman',
            },
            component: () => import('../views/media/pengumuman/category.vue'),
          },
        ],
      },

      /**
       * Media Release
       */

      {
        path: '/administrator/media/release',
        component: () => import('../views/media/release'),
        redirect: '/administrator/media/release/list',
        children: [
          {
            name: 'release_list',
            path: 'list',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Release Update Mobile',
            },
            component: () => import('../views/media/release/list.vue'),
          },
          {
            name: 'list_website_release',
            path: 'website',
            meta: {
              requiresAuth: true,
              page_name: 'Daftar Release Update Website',
            },
            component: () => import('../views/media/release/web.vue'),
          },
          {
            name: 'release_create',
            path: 'create',
            meta: {
              requiresAuth: true,
              page_name: 'Publish Release',
            },
            component: () => import('../views/media/release/create.vue'),
          },
          {
            name: 'release_update',
            path: 'edit/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Release',
            },
            component: () => import('../views/media/release/update.vue'),
          },
          {
            name: 'release_update_website',
            path: 'edit-release-website/:id',
            meta: {
              requiresAuth: true,
              page_name: 'Edit Release',
            },
            component: () => import('../views/media/release/update_web.vue'),
          },
        ],
      },
    ],
  },
]

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// export default router;

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'open',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // },
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  NProgress.set(0.1)

  if (to.name != 'login') {
    if (TokenService.getToken() == null) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    if (TokenService.getToken() != null) {
      next({ path: '/' })
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  setTimeout(() => NProgress.done(), 500)
  document.title = to.meta.title || 'Pasarsafe Admin - ' + to.meta.page_name
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

router.afterEach(() => {})

export default router
